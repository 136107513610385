<template>
  <div
    class="sb_table-cell"
    :class="{
      clickable: clickable || (isHeader && column.sortable),
      overflow: !isFilter && column.width != undefined,
    }"
    @click="handleClick"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SbTableCell',

  props: {
    column: {
      type: Object,
      required: true,
    },

    isHeader: {
      type: Boolean,
      default: undefined,
    },

    isFilter: {
      type: Boolean,
      default: false,
    },
  },

  inject: ['config', 'defaultColumnWidth'],

  computed: {
    clickable() {
      return !this.isHeader && this.column.action;
    },
  },

  methods: {
    handleClick() {
      if (!this.clickable) return;
      this.$emit('click', this.column.key);
    },
  },
};
</script>

<style lang="scss">
.sb_table-cell {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background: $brand-white;
  white-space: nowrap;

  &.overflow {
    overflow: auto;
    position: relative;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.clickable {
    color: $brand-primary;
    cursor: pointer;
    font-weight: 700;
    transition: 0.3s;

    &:hover {
      color: $brand-black;
      transition-duration: 0s;
    }
  }
}
</style>
