<template>
  <poptip v-model="showPopup" trigger="click" :disabled="!rowActions.length">
    <div class="sb_table-actions" :class="{ disabled: !rowActions.length }">
      <sb-icon icon-id="icon_more" />
    </div>
    <template slot="content">
      <div
        v-for="[key, label] in rowActions"
        :key="key"
        class="sb_table-actions_action"
        @click="handleAction(key)"
      >
        {{ label }}
      </div>
    </template>
  </poptip>
</template>

<script>
export default {
  name: 'SbTableRowAction',

  props: { row: { type: Object, required: true } },

  inject: ['config'],

  data() {
    return { showPopup: false };
  },

  computed: {
    rowActions() {
      return this.config.rowActions
        .map((actions) =>
          typeof actions === 'function' ? actions(this.row) : actions,
        )
        .filter((action) => action.length === 2);
    },
  },

  methods: {
    handleAction(key) {
      this.$emit('action', key);
      this.showPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './table-style-settings.scss';

.sb_table-actions {
  width: $util-column-width;
  height: $row-height;
  cursor: pointer;
  color: $brand-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s color;

  &:hover {
    color: $brand-black;
    transition-duration: 0s;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &_action {
    cursor: pointer;
    font-weight: 700;
    color: $brand-primary;
    transition: color 0.3s;
    &:hover {
      color: $brand-black;
      transition-duration: 0s;
    }
  }
}
</style>
