<template>
  <div ref="table" class="sb_table2">
    <sb-loading
      v-if="loading"
      width="100%"
      height="100%"
      position="absolute"
    ></sb-loading>

    <!-- navigation -->
    <div v-if="withNavigation" class="sb_table2_navigation-container">
      <slot name="header-area"></slot>
      <sb-table-bulk-actions
        v-if="hasBulkActions"
        v-model="bulkAction"
        :selected-rows="selectedRows"
        top
        @submit="handleBulkAction"
      />

      <!-- placeholder / grid filler -->
      <div v-else />

      <sb-table-navigation
        v-model="navigation"
        :pagination="pagination"
        :custom="hasCustomNavigation"
        :has-next-page="hasNextPage"
        :has-previous-page="hasPreviousPage"
        @next="!!$listeners.next && $listeners.next($event)"
        @previous="!!$listeners.previous && $listeners.previous($event)"
        @change="updateTableData"
      />
    </div>

    <sb-table-scroll-bar v-if="scrollable" ref="scrollBarTop" top />

    <div ref="layout" class="sb_table2_layout">
      <!-- auto layout -->
      <div
        class="sb_table2_auto-layout"
        :style="{
          paddingLeft: fixedColumnsTotalWidthLeft,
          paddingRight: parseInt(fixedColumnsTotalWidthRight) + 1 + 'px',
        }"
      >
        <div
          ref="innerAutoLayout"
          class="sb_table2_auto-layout_inner table-grid"
          :style="{ gridTemplateColumns: autoColumnsGridTemplate }"
        >
          <!-- auto layout filters -->
          <template v-if="doRenderFilterRow">
            <template v-for="(column, columnIndex) in autoColumns">
              <sb-table-filter
                v-if="column.filter"
                ref="filters"
                :key="`filter-auto-${columnIndex}-${column.key}`"
                class="table-header"
                :column="column"
                @change="handleFilterChange"
              />
              <sb-table-cell
                v-else
                :key="`filter-space-auto-${columnIndex}-${column.key}`"
                class="placeholder"
                :column="column"
              />
            </template>
          </template>

          <!-- auto layout headers -->
          <template v-for="(column, columnIndex) in autoColumns">
            <sb-table-header
              ref="headers"
              :key="`header-auto-${columnIndex}-${column.key}`"
              class="table-header"
              :column="column"
              @sort="handleSortChange"
            />
          </template>

          <!-- auto layout data -->
          <template v-for="(row, rowIndex) in tableData">
            <sb-table-cell
              v-for="(column, columnIndex) in autoColumns"
              :key="`row-auto-${columnIndex}-${rowIndex}-${column.key}`"
              :column="column"
              :data-row="rowIndex"
              @click="handleCellClick($event, row)"
              @mouseenter.native="handleCellMouseEnter"
              @mouseleave.native="handleCellMouseLeave"
            >
              <sb-table-slot :row="row" :column="column">
                {{ getCellData(row, column) }}
              </sb-table-slot>
            </sb-table-cell>
          </template>
        </div>
      </div>

      <!-- fixed layout left -->
      <div
        class="sb_table2_fixed-layout-left table-grid"
        :class="{ 'with-shadow': scrollable }"
        :style="{
          width: fixedColumnsTotalWidthLeft,
          gridTemplateColumns: fixedColumnsLeftGridTemplate,
          border: scrollable ? 'none' : undefined,
        }"
      >
        <!-- left fixed layout filters -->
        <div
          v-if="hasBulkActions"
          class="sb_table2_checkbox-container table-header"
        />
        <template v-if="doRenderFilterRow">
          <template v-for="(column, columnIndex) in fixedColumnsLeft">
            <sb-table-filter
              v-if="column.filter"
              ref="filters"
              :key="`filter-left-${columnIndex}-${column.key}`"
              class="table-header"
              :column="column"
              @change="handleFilterChange"
            />
            <sb-table-cell
              v-else
              :key="`filter-space-left-${columnIndex}-${column.key}`"
              class="table-header"
              :column="column"
            />
          </template>
        </template>

        <!-- left fixed layout headers -->
        <div
          v-if="hasBulkActions"
          class="sb_table2_checkbox-container table-header"
        >
          <checkbox
            :value="tableData.length === selectedRows.length"
            @input="handleMasterSelect"
          />
        </div>
        <template v-for="column in fixedColumnsLeft">
          <sb-table-header
            ref="headers"
            :key="column.key"
            class="table-header"
            :column="column"
            @sort="handleSortChange"
          />
        </template>

        <!-- left fixed layout data -->

        <template v-for="(row, rowIndex) in tableData">
          <div
            v-if="hasBulkActions"
            :key="`checkbox-${rowIndex}`"
            :data-row="rowIndex"
            class="sb_table2_checkbox-container"
            @mouseenter="handleCellMouseEnter"
            @mouseleave="handleCellMouseLeave"
          >
            <checkbox
              :value="selectedRows.includes(row)"
              @input="handleRowSelect($event, row)"
            />
          </div>
          <template v-for="column in fixedColumnsLeft">
            <sb-table-cell
              :key="`cell-${rowIndex}-${column.key}`"
              :column="column"
              :data-row="rowIndex"
              @click="handleCellClick($event, row)"
              @mouseenter.native="handleCellMouseEnter"
              @mouseleave.native="handleCellMouseLeave"
            >
              <sb-table-slot :row="row" :column="column">
                {{ getCellData(row, column) }}
              </sb-table-slot>
            </sb-table-cell>
          </template>
        </template>
      </div>

      <!-- fixed layout right -->
      <div
        class="sb_table2_fixed-layout-right table-grid"
        :class="{ 'with-shadow': scrollable }"
        :style="{
          width: fixedColumnsTotalWidthRight,
          gridTemplateColumns: fixedColumnsRightGridTemplate,
          border: scrollable ? 'none' : undefined,
        }"
      >
        <!-- right fixed layout filters -->
        <template v-if="doRenderFilterRow">
          <template v-for="column in fixedColumnsRight">
            <sb-table-filter
              v-if="column.filter"
              :key="`filter-${column.key}`"
              ref="filters"
              class="table-header $filter"
              :column="column"
              @change="handleFilterChange"
            />
            <sb-table-cell
              v-else
              :key="`no-filter-${column.key}`"
              class="table-header"
              :column="column"
            />
          </template>
        </template>

        <div
          v-if="hasRowActions && doRenderFilterRow"
          class="sb_table2_action-button-container table-header"
        />

        <!-- right fixed layout headers -->
        <template v-for="column in fixedColumnsRight">
          <sb-table-header
            ref="headers"
            :key="`header-${column.key}`"
            class="table-header"
            :column="column"
            @sort="handleSortChange"
          />
        </template>
        <div
          v-if="hasRowActions"
          class="sb_table2_action-button-container table-header"
        />

        <!-- right fixed layout data -->
        <template v-for="(row, rowIndex) in tableData">
          <template v-for="column in fixedColumnsRight">
            <sb-table-cell
              :key="`cell-${rowIndex}-${column.key}`"
              :column="column"
              :data-row="rowIndex"
              @click="handleCellClick($event, row)"
              @mouseenter.native="handleCellMouseEnter"
              @mouseleave.native="handleCellMouseLeave"
            >
              <sb-table-slot :row="row" :column="column">
                {{ getCellData(row, column) }}
              </sb-table-slot>
            </sb-table-cell>
          </template>
          <sb-table-row-actions
            v-if="hasRowActions"
            :key="`cell-actions-${rowIndex}`"
            :data-row="rowIndex"
            :row="row"
            class="sb_table2_action-button-container"
            @action="handleRowAction($event, row)"
            @mouseenter.native="handleCellMouseEnter"
            @mouseleave.native="handleCellMouseLeave"
          >
            <sb-icon icon-id="icon_more" />
          </sb-table-row-actions>
        </template>
      </div>
    </div>

    <sb-table-scroll-bar v-if="scrollable" ref="scrollBarBottom" bottom />

    <!-- navigation -->
    <div v-if="withNavigation" class="sb_table2_navigation-container">
      <sb-table-bulk-actions
        v-if="hasBulkActions"
        v-model="bulkAction"
        :selected-rows="selectedRows"
        bottom
        @submit="handleBulkAction"
      />

      <div v-else />

      <sb-table-navigation
        v-model="navigation"
        :pagination="pagination"
        :custom="hasCustomNavigation"
        :has-next-page="hasNextPage"
        :has-previous-page="hasPreviousPage"
        @next="!!$listeners.next && $listeners.next($event)"
        @previous="!!$listeners.previous && $listeners.previous($event)"
        @change="updateTableData"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { changeLoggerMixin } from '@/mixins/changeLogger';
import { TableConfig } from './TableConfig';
import { kebabCase } from 'lodash';
import SbTableCell from './SbTableCell.vue';
import SbTableFilter from './SbTableFilter.vue';
import SbTableHeader from './SbTableHeader.vue';
import SbTableNavigation from './SbTableNavigation.vue';
import SbTableBulkActions from './SbTableBulkActions.vue';
import SbTableRowActions from './SbTableRowActions.vue';
import SbTableScrollBar from './SbTableScrollBar.vue';
import SbTableSlot from './SbTableSlot';
import SbLoading from '../SbLoading.vue';

const DEFAULT_PAGE_SIZES = [10, 20, 50];

export default {
  name: 'SbTable',

  components: {
    SbTableFilter,
    SbTableHeader,
    SbTableCell,
    SbTableNavigation,
    SbTableBulkActions,
    SbTableRowActions,
    SbTableScrollBar,
    SbTableSlot,
    SbLoading,
  },

  mixins: [changeLoggerMixin([])],

  props: {
    config: {
      type: TableConfig,
      required: true,
    },

    data: {
      type: Array,
      required: true,
    },

    totalCount: {
      type: Number,
      default: undefined,
    },

    hasNextPage: {
      type: Boolean,
      default: true,
    },

    hasPreviousPage: {
      type: Boolean,
      default: true,
    },

    singularSorting: {
      type: Boolean,
      default: false,
    },

    withNavigation: {
      type: Boolean,
      default: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scrollable: false,
      tableData: [],
      navigation: {
        page: 0,
        pageSize: this.config.pageSizes?.[0] ?? DEFAULT_PAGE_SIZES[0],
      },
      sortHeaders: [],
      selectedRows: [],
      bulkAction: undefined,
    };
  },

  computed: {
    autoColumnsGridTemplate() {
      return this.autoColumns
        .map((column) =>
          column.width !== undefined ? column.width + 'px' : 'auto',
        )
        .join(' ');
    },

    fixedColumnsLeftGridTemplate() {
      const columns = this.fixedColumnsLeft
        .map((column) => column.width + 'px')
        .join(' ');

      if (!this.hasBulkActions) return columns;
      return ['50px', columns].join(' ');
    },

    fixedColumnsRightGridTemplate() {
      const columns = this.fixedColumnsRight
        .map((column) => column.width + 'px')
        .join(' ');

      if (!this.hasRowActions) return columns;
      return [columns, '50px'].join(' ');
    },

    autoColumns() {
      return this.config.columns.filter((column) => !column.fixed);
    },

    fixedColumnsLeft() {
      return this.config.columns.filter((column) => column.fixed === 'left');
    },

    fixedColumnsTotalWidthLeft() {
      return (
        this.fixedColumnsLeft.reduce((acc, column) => acc + column.width, 0) +
        (this.hasBulkActions ? this.checkboxContainerWidth : -1) +
        this.fixedColumnsLeft.length +
        1 +
        'px'
      );
    },

    fixedColumnsRight() {
      return this.config.columns.filter((column) => column.fixed === 'right');
    },

    fixedColumnsTotalWidthRight() {
      return (
        this.fixedColumnsRight.reduce((acc, column) => acc + column.width, 0) +
        (this.hasRowActions ? this.checkboxContainerWidth : -1) +
        this.fixedColumnsRight.length +
        'px'
      );
    },

    pagination() {
      return {
        totalCount: this.totalCount ?? this.data.length,
        pageSizes: this.config.pageSizes || DEFAULT_PAGE_SIZES,
      };
    },

    hasCustomNavigation() {
      return !!this.$listeners.next || !!this.$listeners.previous;
    },

    hasCustomSorting() {
      return !!this.$listeners.sort;
    },

    hasCustomFilters() {
      return !!this.$listeners.filter;
    },

    hasBulkActions() {
      return !!this.config.bulkActions?.length;
    },

    hasRowActions() {
      return !!this.config.rowActions?.length;
    },

    scrollBars() {
      return [this.$refs.scrollBarTop, this.$refs.scrollBarBottom];
    },

    doRenderFilterRow() {
      return this.config.columns.filter((column) => !!column.filter).length > 0;
    },
  },

  watch: {
    data(value) {
      this.updateTableData();
      this.$refs.scrollBarTop?.setup();
      this.$refs.scrollBarBottom?.setup();
      this.$emit('change', value);
    },

    navigation: {
      handler() {
        this.$emit('navigate', _.cloneDeep(this.navigation));
      },
      deep: true,
    },
  },

  provide() {
    const self = this;
    return {
      defaultColumnWidth: (100 / this.config.columns.length).toFixed(2) + '%',
      columnCount: this.config.columns.length,
      config: this.config,
      getTable() {
        return self;
      },
      getInnerLayout() {
        return self.$refs.innerAutoLayout;
      },
    };
  },

  created() {
    this.__isTable = true;
    this.filterRowHeight = 50;
    this.checkboxContainerWidth = 50;
    this.dataRowElementsLookup = {};
    if (this.$options.propsData.config.__ob__) {
      console.warn('Table config should not be static, got Observer.');
    }
  },

  mounted() {
    this.updateTableData();
    this.$emit('mount', this);
  },

  updated() {
    requestAnimationFrame(() => {
      const layout = this.$refs.innerAutoLayout;
      this.scrollable = layout
        ? layout.scrollWidth > layout.offsetWidth
        : false;
    });

    this.dataRowElementsLookup = {};
  },

  methods: {
    getDataRowElements(row) {
      return (
        this.dataRowElementsLookup[row] ||
        (this.dataRowElementsLookup[row] = this.$refs.layout.querySelectorAll(
          `[data-row="${row}"]`,
        ))
      );
    },

    handleCellMouseEnter(event) {
      this.getDataRowElements(
        event.currentTarget.dataset.row,
      ).forEach((element) => element.classList.add('s-hover'));
    },

    handleCellMouseLeave(event) {
      this.getDataRowElements(
        event.currentTarget.dataset.row,
      ).forEach((element) => element.classList.remove('s-hover'));
    },

    handleCellClick(event, row) {
      this.$emit('cell-click', { [event]: _.cloneDeep(row) });
      this.$emit(`cell-click-${kebabCase(event)}`, _.cloneDeep(row));
    },

    handleBulkAction(event) {
      this.$emit('bulk-action', { [event]: _.cloneDeep(this.selectedRows) });
      this.$emit(`bulk-action-${event}`, _.cloneDeep(this.selectedRows));
    },

    handleRowAction(event, row) {
      this.$emit('row-action', { [event]: _.cloneDeep(row) });
      this.$emit(`row-action-${event}`, _.cloneDeep(row));
    },

    handleMasterSelect(selected) {
      if (selected) {
        this.selectedRows = this.tableData;
      } else {
        this.selectedRows = [];
      }
    },

    handleRowSelect(selected, row) {
      if (selected && !this.selectedRows.includes(row)) {
        this.selectedRows.push(row);
      } else if (this.selectedRows.includes(row)) {
        this.selectedRows = this.selectedRows.filter((_row) => _row !== row);
      }
    },

    unselectAllRows() {
      this.handleMasterSelect(false);
    },

    selectAllRows() {
      this.handleMasterSelect(true);
    },

    handleFilterChange(event) {
      const filters = this.$refs.filters || [];

      if (this.hasCustomFilters) {
        // emit filter event with event data: { <key>: { <filter settings> } }
        this.$emit(
          'filter',
          filters.reduce(
            (acc, filter) => (
              (acc[filter.column.key] = {
                column: filter.column,
                type: filter.column.filter,
                checked: filter.checked,
                checkedOptional: filter.checkedOptional,
                compareOperator: filter.compareOperator,
                value: filter.filterValue,
              }),
              acc
            ),
            {},
          ),
        );
      } else {
        this.updateTableData();
      }
    },

    handleSortChange(header) {
      if (!this.sortHeaders.includes(header)) {
        this.sortHeaders.push(header);
      }

      this.sortHeaders = this.sortHeaders.filter((header) => !!header.sort);

      if (this.hasCustomSorting) {
        // emit sort event with event data: { <key>: <sort type> } e.g. { name: "ascending" }
        this.$emit(
          'sort',
          this.singularSorting
            ? this.sortHeaders
                .slice(-1)
                .reduce(
                  (acc, header) => (
                    (acc[header.column.key] = header.sort), acc
                  ),
                  {},
                )
            : this.sortHeaders.reduce(
                (acc, header) => ((acc[header.column.key] = header.sort), acc),
                {},
              ),
        );
      } else {
        this.updateTableData();
      }
    },

    updateTableData() {
      const filters = this.$refs.filters || [];
      const headers = this.sortHeaders;
      const { page, pageSize } = this.navigation;
      let _data = JSON.parse(JSON.stringify(this.data));

      /**
       * Handle sorting
       */

      if (!this.hasCustomSorting) {
        headers.forEach((header) => {
          _data = header.applySort(_data);
        });
      }

      /**
       * Handle filters
       */

      if (!this.hasCustomFilters) {
        _data = filters
          .reduce((acc, filter) => filter.applyFilter(acc), _data)
          .slice(page * pageSize, page * pageSize + pageSize);
      }

      // update state
      this.tableData = _data;
    },

    getCellData(row, column) {
      if (!row || !column) return;
      return column.data?.(row) ?? row[column.key];
    },
  },
};
</script>

<style lang="scss">
@import './table-style-settings.scss';

.sb_table2 {
  position: relative;

  .ivu-checkbox,
  .ivu-checkbox-wrapper {
    margin: 0;
    height: 20px;
  }

  .table-grid {
    display: grid;
    grid-auto-rows: $row-height;
    gap: 1px;
  }

  .placeholder {
    cursor: default;
  }

  .placeholder,
  .table-header {
    background: $header-background-color;
  }

  .sb_table-cell,
  .sb_table2_action-button-container,
  .sb_table2_checkbox-container {
    transition: 0.3s;

    &.s-hover {
      background: $brand-primary-lightest;
      transition-duration: 0s;
    }
  }

  &_action-button-container,
  &_checkbox-container,
  &_filler {
    width: $util-column-width;
    background: $brand-white;
  }

  &_navigation-container {
    display: flex;
    justify-content: space-between;

    & > * {
      align-items: center;
    }
  }

  &_layout {
    position: relative;
    border: 1px solid $border-color;
  }

  &_auto-layout {
    background: $border-color;

    &_inner {
      overflow-x: auto;
      scrollbar-width: none; // firefox

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }

  &_fixed-layout-left {
    position: absolute;
    left: 0;
    top: 0;
    border-right: 1px solid $border-color;

    &.with-shadow {
      &::before {
        position: absolute;
        width: 30px;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.05) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        right: -30px;
        top: 0;
        content: '';
      }
    }
  }

  &_fixed-layout-right {
    position: absolute;
    right: 0;
    top: 0;

    &.with-shadow {
      &::before {
        position: absolute;
        width: 30px;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.05) 100%
        );
        left: -30px;
        top: 0;
        content: '';
      }
    }
  }

  &_checkbox-container {
    width: $util-column-width;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
