// TODO
// - column sub rows
// - row actions
export class TableConfig {
  constructor(settings) {
    const errors = [];
    this.fixedTypes = ['left', 'right'];
    this.filterTypes = [
      'search',
      'compare',
      'boolean',
      'boolean-optional',
      'date',
    ];
    this.sortTypes = ['descending', 'ascending'];
    this.compareOperators = ['lt', 'lte', 'equals', 'gte', 'gt'];

    /**
     * Validate bulk actions
     */

    if (settings.bulkActions && !Array.isArray(settings.bulkActions)) {
      errors.push('`bulkActions` should be an array of string tuples');
    }

    /**
     * Validate row actions
     */

    if (settings.rowActions && !Array.isArray(settings.rowActions)) {
      errors.push(
        '`rowActions` should be an array of string tuples, or functions that return string tuples.',
      );
    }

    /**
     * Validate pagination
     */

    if (
      settings.pagination?.pageSizes &&
      !Array.isArray(settings.pagination.pageSizes)
    ) {
      errors.push('`pagination.pageSizes` should be an array of numbers');
    }

    if (
      settings.pagination?.totalCount != undefined &&
      typeof settings.pagination.totalCount !== 'number'
    ) {
      errors.push('`pagination.totalCount` should be a number');
    }

    /**
     * Validate columns
     */

    if (
      !Array.isArray(settings.columns) ||
      !settings.columns.every((col) => typeof col === 'object')
    ) {
      errors.push('`columns` should be an array of objects');
    } else {
      settings.columns.forEach(({ key, filter, fixed, width }, index) => {
        // check key
        if (!key) errors.push(`Missing \`key\` in column at index ${index}`);

        // check filter
        if (filter && !this.filterTypes.includes(filter)) {
          errors.push(
            `Invalid column filter "${filter}". Should be one of ${this.filterTypes
              .map((f) => `"${f}"`)
              .join(', ')}`,
          );
        }

        // check fixed type
        if (fixed && !this.fixedTypes.includes(fixed)) {
          errors.push(
            `Invalid column fixed type "${fixed}". Should be one of ${this.fixedTypes
              .map((t) => `"${t}"`)
              .join(', ')}`,
          );
        }

        // check width on fixed type
        if (fixed && !width) {
          console.log({ key, filter, fixed, width });
          errors.push(`Missing width value in column at index ${index}`);
        }
      });
    }

    if (errors.length) {
      throw new Error(`Invalid table config:\n${errors.join('\n')}`);
    }

    Object.assign(this, settings);
  }
}

/**
 * Example config
 */

if (process.env.NODE_ENV === 'development') {
  new TableConfig({
    pagination: {
      pageSizes: [10, 20, 50],
      totalCount: 100,
    },

    bulkActions: [
      // key, label bulk actions
      ['delete', 'Verwijderen'], // use by key like @bulk-action-delete or generic @bulk-action
      ['mail', 'Mail'], // use by key like @bulk-action-mail or generic @bulk-action
    ],

    rowActions: [
      // key, label row actions
      ['display', 'Weergeven'], // use by key like @row-action-display or generic @row-action
    ],

    columns: [
      {
        key: 'email', // key corresponding to data object member e.g. key `email` from -> { "email": "steve@mailinator.com" }
        header: 'E-mailadres', // shows at column head
        sortable: true, // should be sortable
        sort: 'ascending', // default sorting order
        filter: 'search', // 'search', 'compare', 'boolean'
        checked: true, // default checked for when filter type === 'boolean'
        fixed: 'left', // fix the column to 'left' or 'right'
        width: 200,
        action: true, // is clickable, use by key like @cell-click-email or generic @cell-click
      },
      {
        key: 'friends',
        sortable: true,

        // some custom sorting function
        sort(a, b) {
          return a.property - b.property;
        },

        // custom cell content
        data: (row) => row.friends.map((friend) => friend.email),
      },
    ],
  });
}
