<template>
  <div
    ref="container"
    class="sb_table2_scroll-bar"
    :style="{
      borderTopWidth: top ? '1px' : '0px',
      borderBottomWidth: bottom ? '1px' : '0px',
    }"
  >
    <div ref="bar" class="sb_table2_scroll-bar_bar">
      <div class="inner" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    top: {
      type: Boolean,
      required: false,
    },
    bottom: {
      type: Boolean,
      required: false,
    },
  },

  inject: ['getTable', 'getInnerLayout'],

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      requestAnimationFrame(() => {
        const layout = this.getInnerLayout();
        if (!layout) return;
        const barContainerWidth = this.$refs.container.offsetWidth;
        const scrollBarScale = layout.offsetWidth / layout.scrollWidth;
        const barScrollDistance = barContainerWidth * (1 - scrollBarScale);
        const layoutScrollDistance = layout.scrollWidth - layout.offsetWidth;
        const scrollBars = this.getTable().scrollBars;
        const partner = scrollBars.filter((e) => e != this)[0].$el.firstChild;

        gsap.set(this.$refs.bar, {
          width: scrollBarScale * 100 + '%',
        });

        Draggable.create(this.$refs.bar, {
          type: 'x',
          bounds: this.$refs.container,

          onDrag() {
            gsap.set(partner, { x: this.x });
            layout.scrollLeft = layoutScrollDistance * (this.x / this.maxX);
          },
        });

        layout.addEventListener('scroll', (event) => {
          scrollBars.forEach((e) => {
            const distance = event.currentTarget.scrollLeft;
            const scale = distance / layoutScrollDistance;
            gsap.set(e.$el.firstChild, {
              x: barScrollDistance * scale,
            });
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_table2_scroll-bar {
  border-style: solid;
  border-color: $brand-light-gray;
  border-left-width: 1px;
  border-right-width: 1px;
  height: 20px;

  &_bar {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 5px;

    .inner {
      width: 100%;
      height: 10px;
      border-radius: 5px;
      background: $brand-light-gray;
    }
  }
}
</style>
