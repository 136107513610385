<template>
  <div class="sb_three-states-button">
    <button
      v-if="state === null"
      type="button"
      :disabled="disabled"
      class="sb_three-states-button_btn v_dash"
      @click="clickState"
    >
      <sb-icon icon-id="icon_dash" />
    </button>
    <button
      v-if="state === true"
      type="button"
      :disabled="disabled"
      class="sb_three-states-button_btn v_check"
      @click="clickState"
    >
      <sb-icon icon-id="icon_check" />
    </button>
    <button
      v-if="state === false"
      type="button"
      :disabled="disabled"
      class="sb_three-states-button_btn v_cross"
      @click="clickState"
    >
      <sb-icon icon-id="icon_cross" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    state: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    clickState() {
      if (this.state === null) this.state = true;
      if (this.state === true) this.state = false;
      if (this.state === false) this.state = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_three-states-button {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &_btn {
    width: 26px;
    height: 26px;
    font-size: 1.1rem;
    background: $brand-gray;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
    }

    &.v_dash {
      background: $brand-gray;
    }

    &.v_cross {
      background: $brand-vibe-red;
    }

    &.v_check {
      background: $brand-vibe-green;
    }
  }
}
</style>
