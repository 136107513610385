<template>
  <sb-table-cell class="sb_table-filter" :column="column" is-filter>
    <sb-table-slot
      v-if="slotContent"
      :column="column"
      :filter="self"
      type="filter"
    />

    <template v-else>
      <i-select
        v-if="['compare', 'date'].includes(column.filter)"
        v-model="compareOperator"
        class="sb_table-filter_select"
        size="small"
        clearable
        @on-open-change="selectIsOpen = $event"
      >
        <i-option key="_" value="" @click="log('no filter')">
          Geen filter
        </i-option>
        <i-option
          v-for="operator in getCompareOperators(column.filter)"
          :key="operator"
          :value="operator"
        >
          {{
            selectIsOpen ? operatorLabels[operator] : operatorSigns[operator]
          }}
        </i-option>
      </i-select>

      <i-input
        v-if="['compare', 'search'].includes(column.filter)"
        v-model="filterValue"
        class="sb_table-filter_input"
        size="small"
        clearable
        :placeholder="column.filterPlaceholder"
        :suffix="column.filter !== 'compare' ? 'ios-search' : undefined"
      />

      <date-picker
        v-if="column.filter === 'date'"
        v-model="filterValue"
        size="small"
        format="dd-MM-yyyy"
        placeholder="dd-mm-jjjj"
        clearable
      />

      <checkbox v-if="column.filter === 'boolean'" v-model="checked" />

      <sb-three-states-button
        v-if="column.filter === 'boolean-optional'"
        v-model="checkedOptional"
      />
      <!-- <i-select
       
       
        class="sb_table-filter_select"
        size="small"
        clearable
        @on-open-change="selectIsOpen = $event"
      >
        <i-option value="skip">Geen filter</i-option>
        <i-option value="yes">Ja</i-option>
        <i-option value="no">Nee</i-option>
      </i-select> -->
    </template>
  </sb-table-cell>
</template>

<script>
import { kebabCase } from 'lodash';
import SbTableCell from './SbTableCell.vue';
import SbTableSlot from './SbTableSlot';
import SbThreeStatesButton from '../SbThreeStatesButton.vue';

export default {
  name: 'SbTableFilter',
  components: { SbTableCell, SbTableSlot, SbThreeStatesButton },

  props: {
    column: { type: Object, required: true },
  },

  inject: ['config', 'defaultColumnWidth', 'getTable'],

  data() {
    return {
      checked: this.column.checked,
      checkedOptional: this.column.checkedOptional,
      compareOperator: undefined,
      filterValue: undefined,
      selectIsOpen: false,
    };
  },

  computed: {
    self() {
      return this;
    },

    slotContent() {
      return this.getTable().$scopedSlots[
        `filter-${kebabCase(this.column.key)}`
      ];
    },
  },

  watch: {
    compareOperator(value, prevValue) {
      if (!value && !!prevValue) {
        this.filterValue = undefined;
      }
    },
  },

  mounted() {
    this.$watch(
      () => [
        this.checked,
        this.compareOperator,
        this.filterValue,
        this.checkedOptional,
      ],
      (values) => {
        const [_checked, operator, value, _checkedOptional] = values;
        const isCompareFilter = ['compare', 'date'].includes(
          this.column.filter,
        );

        if (isCompareFilter) {
          if (!operator && !value) return this.$emit('change', this);
          if (!operator || !value) return;
        }

        this.$emit('change', this);
      },
      { immediate: true },
    );
  },

  created() {
    // if (!this.$parent.__isTableRow) {
    //   throw new Error('SbTableFilter should be used as child of SbTableRow');
    // }
    if (!this.column.key) {
      throw new Error('Filter column requires key');
    }
    if (!this.column.filter) {
      throw new Error('Filter column requires filter type');
    }

    this.operatorLabels = {
      lt: 'Kleiner dan',
      lte: 'Kleiner dan of gelijk aan',
      equals: 'Gelijk aan',
      gte: 'Groter dan of gelijk aan',
      gt: 'Groter dan',
    };

    this.operatorSigns = {
      lt: '<',
      lte: '<=',
      equals: '=',
      gte: '>=',
      gt: '>',
    };
  },

  methods: {
    getCompareOperators(filterType) {
      if (filterType === 'date') {
        return this.config.compareOperators.filter(
          (op) => op !== 'lte' && op !== 'gte',
        );
      }

      return this.config.compareOperators;
    },

    applyFilter(data) {
      let filteredData = data.slice();

      if (this.checked !== undefined) {
        filteredData = filteredData.filter((item) =>
          this.checked === true ? !!item[this.column.key] : true,
        );
      }

      if (this.compareOperator && this.filterValue) {
        filteredData = filteredData.filter((item) => {
          const value = item[this.column.key];
          const asNumber = !isNaN(Number(value));

          switch (this.compareOperator) {
            case 'lt':
              return value < this.filterValue;
            case 'lte':
              return value <= this.filterValue;
            case 'eq':
              return asNumber
                ? Number(value) === Number(this.filterValue)
                : String(value).toLowerCase() ===
                    this.filterValue.toLowerCase();
            case 'gte':
              return value >= this.filterValue;
            case 'gt':
              return value > this.filterValue;
          }
        });
      }

      if (this.column.filter === 'search' && this.filterValue) {
        filteredData = filteredData.filter((item) => {
          const value = item[this.column.key];
          const asNumber = !isNaN(Number(value));

          return asNumber
            ? Number(value) === Number(this.filterValue)
            : String(value)
                .toLowerCase()
                .includes(this.filterValue.toLowerCase());
        });
      }

      return filteredData;
    },
  },
};
</script>

<style lang="scss">
.sb_table-filter {
  display: flex;

  &_select {
    margin-right: 5px;
    flex: 1;
    min-width: 127px;
    max-width: 127px;
  }

  &_input {
    flex: 1;
    max-width: 300px;
  }
}
</style>
