export function vmConfirm(title, content) {
  return new Promise((resolve) => {
    this.$Modal.confirm({
      title,
      content,
      closable: true,
      onOk: () => setTimeout(() => resolve(true), 300),
      onCancel: () => setTimeout(() => resolve(false), 300),
    });
  });
}
