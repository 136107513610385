<template>
  <sb-table-cell
    class="sb_table-header"
    is-header
    :column="column"
    @click.native="column.sortable && handleClick()"
  >
    <div>{{ column.header || column.key }}</div>
    <div v-if="column.sortable" class="sb_table-header_icon-container">
      <sb-icon v-if="sort === 'ascending'" icon-id="icon_caret-up" />
      <sb-icon v-if="sort === 'descending'" icon-id="icon_caret-down" />
    </div>
  </sb-table-cell>
</template>

<script>
import SbTableCell from './SbTableCell.vue';

export default {
  name: 'SbTableHeader',

  components: { SbTableCell },

  props: {
    column: {
      type: Object,
      required: true,
    },
  },

  inject: ['config', 'defaultColumnWidth'],

  data() {
    const defaultSort =
      typeof this.column.sort === 'string'
        ? this.column.sort
        : typeof this.column.sort === 'function'
        ? this.column.sort.applyByDefault
        : undefined;

    return {
      sort: defaultSort,
    };
  },

  mounted() {
    if (this.sort) this.$emit('sort', this);
  },

  methods: {
    handleClick() {
      this.sort =
        this.sort === 'ascending'
          ? 'descending'
          : this.sort === 'descending'
          ? undefined
          : 'ascending';

      this.$emit('sort', this);
    },

    applySort(data) {
      if (!this.column.sortable || !this.sort) return data;

      const sortedData = data.slice();

      sortedData.sort(
        typeof this.column.sort === 'function'
          ? (a, b) => this.column.sort(a, b, this.sort)
          : (a, b) => {
              const order = this.sort;
              const _a = a[this.column.key];
              const _b = b[this.column.key];

              // 0 -> 1
              if (order === 'ascending') {
                if (_a < _b) return -1;
                if (_a > _b) return 1;
                return 0;
              }

              // 1 -> 0
              if (order === 'descending') {
                if (_a > _b) return -1;
                if (_a < _b) return 1;
                return 0;
              }

              return 0;
            },
      );

      return sortedData;
    },
  },
};
</script>

<style lang="scss">
.sb_table-header {
  display: flex;
  font-weight: 700;

  &_icon-container {
    color: $brand-primary;
    font-size: 20px;
    display: flex;
    align-items: center;
  }
}
</style>
