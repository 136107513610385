<template>
  <div class="sb_table-bulk-actions" :class="{ top, bottom }">
    <div class="sb_table-bulk-actions_arrow" :class="{ top, bottom }">
      <svg width="22" height="46" viewBox="0 0 22 46">
        <path
          d="M2 46H0L0 37H2L2 46ZM0 16H2L2 8.05024L18.1987 8.05024L13.8384 12.4836L15.2919 14.0002L21.9999 7.00024L15.2919 0.000244141L13.8384 1.51691L18.1987 6.06691L2 6.06691V6H0L0 6.06691H-6.10352e-05L-6.10352e-05 8.05024H0L0 16Z"
        />
      </svg>
      <div class="sb_table-bulk-actions_arrow_selected-amount">
        {{ selectedRows.length }}
      </div>
    </div>
    <i-select
      class="sb_table-bulk-actions_select"
      placeholder="Acties"
      :value="value"
      :disabled="!selectedRows.length"
      @input="$emit('input', $event)"
    >
      <i-option
        v-for="[key, label] in config.bulkActions"
        :key="key"
        :value="key"
      >
        {{ label }}
      </i-option>
    </i-select>

    <i-button
      class="sb_table-bulk-actions_submit"
      type="text"
      :disabled="!value || !selectedRows.length"
      @click="handleSubmit"
    >
      Toepassen
    </i-button>
  </div>
</template>

<script>
export default {
  name: 'SbTableBulkActions',
  inject: ['config'],
  props: {
    value: { type: String, default: undefined },
    selectedRows: { type: Array, required: true },
    top: { type: Boolean, required: false },
    bottom: { type: Boolean, required: false },
  },

  methods: {
    handleSubmit() {
      this.$emit('submit', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_table-bulk-actions {
  display: flex;
  padding-left: 24px;

  &.top {
    margin-bottom: 5px;
  }

  &.bottom {
    margin-top: 5px;
  }

  &_arrow {
    position: relative;
    color: $brand-light-gray;
    margin-right: 20px;

    &.top {
      margin-top: 40px;
    }

    &.bottom {
      margin-bottom: 40px;
      svg {
        transform: scale(1, -1) translateY(-8px);
      }
    }

    &_selected-amount {
      position: absolute;
      width: 100%;
      height: 20px;
      bottom: 16px;
      left: -10px;
      font-size: 14px;
      text-align: center;
      overflow: visible;
    }

    svg {
      fill: $brand-light-gray;
    }
  }

  &_select {
    width: auto;
    min-width: 150px;
  }

  &_submit {
    margin-left: 5px;
  }
}
</style>
