<template>
  <div class="sb_page-header">
    <div class="sb_page-header_left">
      <slot name="left"></slot>
      <slot />
    </div>
    <div class="sb_page-header_right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbPageHeader',
  props: {},
};
</script>

<style lang="scss" scoped>
.sb_page-header {
  display: flex;
  justify-content: space-between;
  margin: 2.4rem 0;

  @media print {
    display: none;
  }

  &_left {}

  &_right {
    padding: 0 0 0 40px;
  }

  h1 {
    font-weight: 600;

    small {
      font-size: 20px;
      margin: 0 20px;
    }
  }

  h2 {
    font-weight: 600;

    small {
      font-size: 20px;
      margin: 0 20px;
    }
  }

  h3 {
    font-weight: 600;

    small {
      font-size: 20px;
      margin: 0 20px;
    }
  }

  .ivu-dropdown {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
