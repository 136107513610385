import { kebabCase } from 'lodash';

export default {
  name: 'SbTableSlot',
  functional: true,
  inject: ['config', 'getTable'],
  props: {
    row: { type: Object, default: undefined },
    column: { type: Object, required: true },
    type: { type: String, default: 'column' },
    filter: { type: Object },
  },

  render(h, ctx) {
    const row = ctx.props.row;
    const column = ctx.props.column;
    const type = ctx.props.type;
    const table = ctx.injections.getTable();
    const wrapCell = type === 'filter';
    const filterRef = ctx.props.filter;
    const children =
      table.$scopedSlots?.[`${type}-${kebabCase(column.key)}`]?.({
        column,
        content: table.getCellData(row, column),
        row,
        table,
        filterRef,
      }) ?? ctx.children;

    return children;
  },
};
