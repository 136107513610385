<template>
  <div class="sb_table-navigation">
    <div class="sb_table-navigation_total-count">
      Aantal: {{ pagination.totalCount }}
    </div>

    <i-select
      v-model="value.pageSize"
      class="sb_table-navigation_page-size"
      @input="$emit('change')"
    >
      <i-option v-for="size in pagination.pageSizes" :key="size" :value="size">
        {{ size }}
      </i-option>
    </i-select>

    <div class="sb_table-navigation_navigation-buttons">
      <i-button type="text" :disabled="disablePrevious" @click="handlePrevious">
        <icon type="ios-arrow-back" />
        Vorige
      </i-button>

      <i-button type="text" :disabled="disableNext" @click="handleNext">
        Volgende
        <icon type="ios-arrow-forward" />
      </i-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbTableNavigation',

  props: {
    pagination: {
      type: Object,
      required: true,
    },

    custom: {
      type: Boolean,
      required: true,
    },

    value: {
      type: Object,
      required: true,
    },

    hasNextPage: {
      type: Boolean,
      default: true,
    },

    hasPreviousPage: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      pageSize: this.value.pageSize,
    };
  },

  computed: {
    disablePrevious() {
      return (
        (this.custom && (!this.$listeners.previous || !this.hasPreviousPage)) ||
        (!this.custom && this.value.page === 0)
      );
    },

    disableNext() {
      return (
        (this.custom && (!this.$listeners.next || !this.hasNextPage)) ||
        (!this.custom &&
          this.pagination.totalCount <
            (this.value.page + 1) * this.value.pageSize)
      );
    },
  },

  methods: {
    handlePrevious() {
      if (this.custom) {
        return this.$emit('previous');
      }

      this.value.page--;
      this.$emit('change');
    },

    handleNext() {
      if (this.custom) {
        return this.$emit('next');
      }

      this.value.page++;
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_table-navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;

  &_total-count {
    padding: 0 20px;
    color: $brand-gray;
  }

  &_page-size {
    width: 90px;
  }

  &_navigation-buttons {
    margin-left: 5px;
  }
}
</style>
